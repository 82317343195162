$(document).ready(function(){
	$("a[href='#signup_alert']").fancybox({
		overlayColor: "black",
		type: "inline",
        onCleanup: function() {
            $("#signup_alert").appendTo("#signup_placeholder");
          
        }
	});

	$("a[href='#member_alert']").fancybox({
		overlayColor: "black"
	});

	$('a.category').on('click', function(e) {
		var name = e.target.innerHTML
		var $categories = $("#document_chapter_list");
		var value = $categories.val();
		if (value.indexOf(" " + name) == -1 && value.indexOf(name + ",") == -1) {
			$categories.val(value.length > 0 ? value + ", " + name : name);
		}
	})
});
